<template>
  <div class="person-modify-phone">
    <van-nav-bar title="更换密码" left-arrow @click-left="onClickLeft" />

    <div class="main">
      <div class="modify-content">
        <div class="modify-content-item">
          <span class="tit">原密码 &nbsp;:</span>
          <div class="inputbox">
            <input
              :type="showOriPass ? 'text' : 'password'"
              v-model="originPass"
              placeholder="请输入原密码"
            />
          </div>
          <div class="eyes" v-show="hasOriPass" @click="turnOriPass">
            <van-icon name="closed-eye" v-show="!showOriPass" />
            <van-icon name="eye-o" v-show="showOriPass" />
          </div>
          <div class="close" v-show="hasOriPass" @click="delOriPass">
            <img src="@image/close.png" alt="" />
          </div>
          <!-- <div class="notice">
            <p v-show="oriError" class="warn">{{ oriError }}</p>
          </div> -->
        </div>
        <div class="modify-content-item">
          <span class="tit">新密码 &nbsp;:</span>
          <div class="inputbox">
            <input
              :type="showNewPass ? 'text' : 'password'" @blur="onblur"
              v-model="newPass"
              placeholder="请设置新密码8-16位"
            />
          </div>
          <div class="eyes" v-show="hasNewPass" @click="turnNewPass">
            <van-icon name="closed-eye" v-show="!showNewPass" />
            <van-icon name="eye-o" v-show="showNewPass" />
          </div>
          <div class="close" v-show="hasNewPass" @click="delNewPass">
            <img src="@image/close.png" alt="" />
          </div>
          <!-- <div class="notice" v-if="!hasNewPass">
            <p v-show="!newError">密码由8-16位数字、字母组成</p>
            <p v-show="newError" class="warn">{{ newError }}</p>
          </div> -->
        </div>

        <div class="modify-content-item">
          <span class="tit">验证码 :</span>

          <div class="inputbox">
            <input type="number" required @input="setLength" v-model="verif" />
          </div>
          <div class="verif">
            <span class="get-verif" v-if="!isSend" @click="showVerifNotice"
              >获取验证码</span
            >
            <span class="get-verif verif-time" v-if="isSend"
              >重新获取{{ verifOutTime }}s</span
            >
          </div>
          <!-- <div class="notice">
            <p v-show="oriError" class="warn">{{ oriError }}</p>
            <p v-show="!oriError && newError" class="warn">{{ newError }}</p>
            <p v-show="!oriError && !newError && verifError" class="warn">{{ verifError }}</p>
          </div> -->
        </div>
      </div>
      <p class="err">{{ modifyError }}</p>
      <div class="send" @click="submitModify">确认更换</div>
    </div>
    <van-popup v-model="sendVerif">
      <div class="over">
        <div class="content">
          <p>
            我们将发送验证码短信到<br />+86&nbsp;
            <span class="phoneNumber">{{ profile.mobile }}</span>
          </p>
        </div>
        <div class="btn-group">
          <span class="cancel" @click="cancel">取消</span>
          <span class="sure" @click="sure">确定</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getcode, updatePwd } from "@api/user.js";
import { mapState } from "vuex";
import qs from "qs";
export default {
  name : "changepass",
  data() {
    return {
      //原密码
      originPass: "",
      //新密码
      newPass: "",
      //原密码显示
      showOriPass: false,
      //新密码显示
      showNewPass: false,
      //发送验证码
      sendVerif: false,
      //是否已经发送
      isSend: false,
      //超时时间
      verifOutTime: 60,
      //验证码
      verif: "",
      //验证码ID
      codeId: "",
      //校验原密码
      oriError: "",
      //校验新密码
      newError: "",
      //校验验证码
      verifError: "",
      //修改结果校验
      modifyError: "",
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    hasOriPass() {
      if (this.originPass) {
        return true;
      }
      return false;
    },
    hasNewPass() {
      if (this.newPass) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //删除原密码输入
    delOriPass() {
      this.originPass = "";
    },
    //删除新密码输入
    delNewPass() {
      this.newPass = "";
    },
    //切换显示密码开关
    turnOriPass() {
      this.showOriPass = !this.showOriPass;
    },
    turnNewPass() {
      this.showNewPass = !this.showNewPass;
    },
    //打开发送短信提示
    showVerifNotice() {
      if(!this.originPass || !this.newPass){
        this.$toast("请先输入密码");
        return;
      }
      this.sendVerif = true;
    },
    //限制
    setLength() {
      if (this.verif.length > 6) {
        this.verif = this.verif.slice(0, 6);
      }
    },
    onblur(){
      if(this.originPass == this.newPass){
        this.$toast("两次密码不能相同！")
      }else if(this.originPass == ""){
        this.$toast("请输入原密码！")
      }else if(this.newPass == ""){
        this.$toast("请输入新密码！")
      }
    },
    //确认发送验证码
    sure() {
      const params = {
        mobile: this.profile.mobile,
        type: 2,
      };
      getcode(qs.stringify(params))
        .then((res) => {
          this.codeId = res.data.codeId;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      this.sendVerif = false;
      this.isSend = true;
      this.setTime();
    },
    //取消发送验证码
    cancel() {
      this.sendVerif = false;
    },
    // 校验验证码
    setTime() {
      let timer = setInterval(() => {
        if (this.verifOutTime > 0) {
          this.verifOutTime--;
        } else {
          clearInterval(timer);
          this.codeId = "";
          this.verifOutTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },
    //提交修改
    submitModify() {
      if (this.originPass != "" && this.newPass != "" && this.verif != "") {
        if (!this.codeId) {
          this.modifyError = "请填写正确的验证码!";
          return;
        }
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z\d]{8,16}$/;
        if (!reg.test(this.newPass)) {
          this.modifyError = "密码由8-16位数字、字母组成";
          return;
        }
        if(this.newPass == this.originPass){
          this.modifyError = "两次密码不能相同！";
          return;
        }
        const params = {
          code: this.verif,
          codeId: this.codeId,
          mobile: this.profile.mobile,
          password: this.newPass,
        };
        updatePwd(qs.stringify(params))
          .then((res) => {
            if (res.code == 0) {
              setTimeout(() => {
                this.$router.replace("/login");
              }, 500);
              console.log(res.data);
              this.$toast("修改成功,请重新登陆");
            } else {
              this.modifyError = res.msg;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (this.originPass == "") {
          this.oriError = "请输入原密码";
          this.$toast(this.oriError);
          return;
        }
        if (this.newPass == "") {
          this.newError = "请输入新密码";
          this.$toast(this.newError);
          return;
        }
        if (this.verif == "") {
          this.verifError = "请输入验证码";
          this.$toast(this.verifError);
          return;
        }
      }
    },
  },
  watch: {
    originPass() {
      this.oriError = "";
    },
    newPass() {
      this.newError = "";
    },
    verif() {
      this.verifError = "";
    },
    modifyError() {
      let timer = setTimeout(() => {
        this.modifyError = "";
        clearTimeout(timer);
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #333;
}

::v-deep .van-nav-bar__title {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}

::v-deep .van-popup {
  border-radius: 10px;
}
::v-deep .van-icon {
  font-size: 16px;
  color: #999;
}
.over {
  width: 285px;
  height: 169px;

  .content {
    height: 115px;
    padding: 50px 60px 0;
    border-bottom: 1px solid #eee;

    p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0 45px;

    span {
      font-size: 15px;
      font-weight: 500;
      color: #999;
    }

    .sure {
      color: #4588ff;
    }
  }
}

.person-modify-phone {
  font-family: PingFang SC;

  .main {
    border-top: 11px solid #f4f4f4;
    padding: 0 30px;

    .modify-content {
      &-item {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        height: 63px;
        border-bottom: 1px solid #eee;

        .tit {
          width: 60px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }

        .inputbox {
          width: 200px;
          height: 19px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;

          input {
            width: 100%;
            padding: 0 25px 0 16px;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
        }
        .eyes {
          display: flex;
          align-items: center;
          margin-right: 10px;
        }
        .notice {
          position: absolute;
          left: 75px;
          bottom: 0;
          font-size: 12px;
          color: #999;
          .warn {
            color: red;
          }
        }

        .verif {
          width:65px;
          flex-shrink:0;
          display: flex;
          align-items: center;
          margin-left: 15px;
          text-align: center;
          .get-verif,
          .get-verif.verif-time {
            font-size: 11px;
            font-weight: 500;
            color: #4588ff;
          }
          .get-verif.verif-time {
            color: #999;
          }
        }

        .close {
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      &-item:last-child {
        .inputbox {
          flex: 0 0 180px;
          input {
            border-right: 1px solid #eee;
          }
        }
      }
    }
    .err {
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      color: red;
    }
    .send {
          width: 302.5px;
          height: 57px;
          margin: 130px auto;
          line-height: 57px;
          text-align: center;
          font-size: 17px;
          font-weight: 500;
          background: #4588ff;
          color: #fff;
          box-shadow: 0px 8px 8px 0px rgba(69, 136, 255, 0.33);
          border-radius: 49px;;
    }
  }
}
</style>
